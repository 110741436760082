@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap);
* {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
}

body {
  font-family: "Speedee-Regular", Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: "Speedee-Condensed-Bold";
}

@font-face {
  font-family: "Speedee-Regular";
  src: local("Speedee-Regular"),
    url(../../static/media/Speedee-Regular.c195fb03.otf) format("otf"),
    url(../../static/media/Speedee-Regular.3f99d0ef.ttf) format("ttf"),
    url(../../static/media/Speedee-Regular.9c81e9c8.woff) format("woff");
}

@font-face {
  font-family: "Speedee-Bold";
  src: local("Speedee-Bold"),
    url(../../static/media/Speedee-Bold.6213e6e5.otf) format("otf"),
    url(../../static/media/Speedee-Bold.a265a44e.ttf) format("ttf"),
    url(../../static/media/Speedee-Bold.1ec4ca75.woff) format("woff");
}

@font-face {
  font-family: "Speedee-Condensed-Regular";
  src: local("peedee-Condensed-Regular"),
    url(../../static/media/SpeedeeCd-Regular.a403408f.otf) format("otf"),
    url(../../static/media/SpeedeeCd-Regular.dd0c099c.ttf) format("ttf"),
    url(../../static/media/SpeedeeCd-Regular.3eb07b62.woff) format("woff");
}

@font-face {
  font-family: "Speedee-Condensed-Bold";
  src: local("peedee-Condensed-Bold"),
    url(../../static/media/SpeedeeCd-Bold.909225d3.otf) format("otf"),
    url(../../static/media/SpeedeeCd-Bold.c5ecb473.ttf) format("ttf"),
    url(../../static/media/SpeedeeCd-Bold.2e42839d.woff) format("woff");
}

@font-face {
  font-family: MaxEmoji;
  src: url(../../static/media/Brand-Live-Emoji.500dc952.otf) format("opentype");
}

