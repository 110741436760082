@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

* {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
}

body {
  font-family: "Speedee-Regular", Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: "Speedee-Condensed-Bold";
}

@font-face {
  font-family: "Speedee-Regular";
  src: local("Speedee-Regular"),
    url("Fonts/Speedee/Speedee-Regular.otf") format("otf"),
    url("Fonts/Speedee/Speedee-Regular.ttf") format("ttf"),
    url("Fonts/Speedee/Speedee-Regular.woff") format("woff");
}

@font-face {
  font-family: "Speedee-Bold";
  src: local("Speedee-Bold"),
    url("Fonts/Speedee/Speedee-Bold.otf") format("otf"),
    url("Fonts/Speedee/Speedee-Bold.ttf") format("ttf"),
    url("Fonts/Speedee/Speedee-Bold.woff") format("woff");
}

@font-face {
  font-family: "Speedee-Condensed-Regular";
  src: local("peedee-Condensed-Regular"),
    url("Fonts/Speedee/SpeedeeCd-Regular.otf") format("otf"),
    url("Fonts/Speedee/SpeedeeCd-Regular.ttf") format("ttf"),
    url("Fonts/Speedee/SpeedeeCd-Regular.woff") format("woff");
}

@font-face {
  font-family: "Speedee-Condensed-Bold";
  src: local("peedee-Condensed-Bold"),
    url("Fonts/Speedee/SpeedeeCd-Bold.otf") format("otf"),
    url("Fonts/Speedee/SpeedeeCd-Bold.ttf") format("ttf"),
    url("Fonts/Speedee/SpeedeeCd-Bold.woff") format("woff");
}

@font-face {
  font-family: MaxEmoji;
  src: url("Fonts/Emoji/Brand-Live-Emoji.otf") format("opentype");
}
